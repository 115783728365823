import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '../components/layout/Layout';
import Seo from '../components/Seo';
import NotFoundLayout from '../pagesContent/404';


const NotFoundPage = ({ location }: PageProps) => (
  <Layout
    location={location}
  >
    <Seo
      pageType="404"
      title="404: Not found"
      lang="en"
    />
    <NotFoundLayout />
  </Layout>
);

export default NotFoundPage;
